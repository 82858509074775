import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from 'app/auth/models';
import { environment } from 'environments/environment';

import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OrdersService {

  // Private
  private currentUserValue;

  constructor(private _httpClient: HttpClient) {
    this.currentUserValue = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser'))).value;
  }

  getCartItens(): Observable<object> {
    const token = this.currentUserValue;
    let headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    });
    let options = { headers: headers };
    return this._httpClient.get(`${environment.apiUrl}/user/cart`, options);
  }

  addToCart(ref, qt) {
    const token = this.currentUserValue;
    let headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    });
    let options = { headers: headers };
    return this._httpClient.post(`${environment.apiUrl}/user/cart/add`, { 'ref': ref, 'qt': qt }, options);
  }

  removeFromCart(ref) {
    const token = this.currentUserValue;
    let headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    });
    let options = { headers: headers };
    return this._httpClient.post(`${environment.apiUrl}/user/cart/remove`, { 'ref': ref }, options);
  }

  editCart(ref, qt) {
    const token = this.currentUserValue;
    let headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    });
    let options = { headers: headers };
    return this._httpClient.post(`${environment.apiUrl}/user/cart/edit`, { 'ref': ref, 'qt': qt }, options);
  }

  getOrderDetails(id) {
    const token = this.currentUserValue;
    let headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    });
    let options = { headers: headers };
    return this._httpClient.get(`${environment.apiUrl}/user/orders/` + id, options);
  }
  getOrders(params) {
    const token = this.currentUserValue;
    let headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    });
    let options = { headers: headers };
    return this._httpClient.get(`${environment.apiUrl}/user/orders` + params, options);
  }

  getOrdersPending(params) {
    const token = this.currentUserValue;
    let headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    });
    let options = { headers: headers };
    return this._httpClient.get(`${environment.apiUrl}/user/orders/pending` + params, options);
  }

  deletePendente(ref) {
    const token = this.currentUserValue;
    let headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    });
    let options = { headers: headers };
    return this._httpClient.get(`${environment.apiUrl}/user/orders/pending-update/` + ref + '/1', options);
  }

  encomendar(obs) {
    const token = this.currentUserValue;
    let headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    });
    let options = { headers: headers };
    return this._httpClient.post(`${environment.apiUrl}/user/orders/new`, { 'obs': obs }, options);
  }
}