<li class="nav-item nav-search" *ngIf="!router.url.includes('/apps/e-commerce/shop')">
  <!-- Search icon -->
  <a class="nav-link nav-link-search" (click)="toggleSearch()"><span [data-feather]="'search'"
      [class]="'ficon'"></span></a>

  <!-- Search -->
  <div class="search-input" [class.open]="openSearchRef === true">
    <!-- Search Input -->
    <form (ngSubmit)="search()" autocomplete="off">
      <div class="search-input-icon" (click)="search()"><span [data-feather]="'search'"></span></div>
      <input class="form-control input" type="text" placeholder="Pesquisar produtos... &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;*em artigos FORCE coloque FOmodelo (ex.: FO75514)" tabindex="-1"
        [(ngModel)]="q" name="q" #openSearch />
    </form>
    <button class="btn search-input-close p-0" (click)="toggleSearch()"><span [data-feather]="'x'"></span></button>

    <!-- Search List -->
    <!--Apaguei o que tava aqui-->
    <!--/ Search List -->
  </div>
</li>