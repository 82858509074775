import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from 'environments/environment';
import { User, Role } from 'app/auth/models';
import { ToastrService } from 'ngx-toastr';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  //public
  public currentUser: Observable<User>;

  //private
  private currentUserSubject: BehaviorSubject<User>;

  /**
   *
   * @param {HttpClient} _http
   * @param {ToastrService} _toastrService
   */
  constructor(private _http: HttpClient, private _toastrService: ToastrService) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  // getter: currentUserValue
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  get isAdmin() {
    return this.currentUser && this.currentUserSubject.value.role === Role.Admin;
  }

  get isClient() {
    return this.currentUser && this.currentUserSubject.value.role === Role.Client;
  }

  get isColaborador() {
    return this.currentUser && this.currentUserSubject.value.role === Role.Colaborador;
  }

  get isComercial() {
    return this.currentUser && this.currentUserSubject.value.role === Role.Comercial;
  }

  get isManager() {
    return this.currentUser && this.currentUserSubject.value.role === Role.Manager;
  }

  /**
   * User login
   *
   * @param email
   * @param password
   * @returns user
   */
  login(email: string, password: string) {
    return this._http
      .post<any>(`${environment.apiUrl}/auth`, { email, password, app: "clientesEUROED" })
      .pipe(
        map(user => {
          if (user && user.token) {
            user.avatar = user.image

            if (user.type == 'comercial') {
              user.role = Role.Comercial
            } else if (user.type == 'gestor') {
              user.role = Role.Manager
            } else if (user.type == 'colaborador') {
              user.role = Role.Colaborador
            } else if (user.type == 'admin') {
              user.role = Role.Admin
            } else {
              user.role = Role.Client
            }

            user.name = user.nome
            user.contribuinte = user.contribuinte
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(user));

            // Display welcome toast!
            setTimeout(() => {
              this._toastrService.success(
                'Sessão iniciada com sucesso como ' +
                  user.role +
                  '.',
                '👋 Bem-vindo, ' + user.name + '!',
                { toastClass: 'toast ngx-toastr', closeButton: true }
              );
            }, 2500);

            // notify
            this.currentUserSubject.next(user);
          } else {
            throw new Error();
          }

          return user;
        })
      );
  }
  /**
   * Current token
   *
   */
  check_current_token() {
    try {
      const token = this.currentUserValue.token;
      let headers = new HttpHeaders({
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json'
      });
      let options = { headers: headers };
      return this._http.get<any>(`${environment.apiUrl}/auth`, options)
      .pipe(
        map(status => {
          if (status && status.status == "success"){
            //pass
          } else {
            this.logout();
            location.reload();
          }
        }));
    } catch {
      //pass
    }
  }
  

  /**
   * User logout
   *
   */
  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    localStorage.clear();
    // notify
    this.currentUserSubject.next(null);
  }
}
