import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OrdersService } from 'app/main/apps/orders/orders.service';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'app-navbar-cart',
  templateUrl: './navbar-cart.component.html'
})
export class NavbarCartComponent implements OnInit {
  // Public
  public products = [];
  public cartDetails = {};
  public cartLength = 0;
  public cartTotal = "0";
  public loaded = 0;

  constructor(public _ordersService: OrdersService, public router: Router) { }



  updateCart(): void {
    this.loaded = 0;
    this._ordersService.getCartItens().subscribe(res => {
      this.products = res['results']['products'];
      this.cartLength = 0;
      for (let item of this.products) {
        this.cartLength += item["qt"];
      }
      if (this.cartLength == 0) {
        this.cartTotal = "0";
      } else {
        this.cartTotal = res['results']['details']["total"]
      }
    }, (e) => {
      //erro
      console.log(e);
      this.loaded = 1;
    },
      () => {
        //sucesso 
        this.loaded = 1;
      });
  }

  removeFromCart(product) {
    this._ordersService.removeFromCart(product.ref).subscribe(res => {
      this.updateCart();
    });
  }

  editCart(product, qt) {
    if (qt == null || !qt) {
      qt = 1;
    }
    this._ordersService.editCart(product.ref, qt).subscribe(res => {
      this.updateCart();
    });
  }

  ngOnInit(): void {
    this.updateCart();

    // Get Products
    /**
    this._ecommerceService.getProducts();

    // Get Cart List
    this._ecommerceService.getCartList();

    // Subscribe to Cart List
    this._ecommerceService.onCartListChange.pipe(takeUntil(this._unsubscribeAll)).subscribe(res => {
      this.cartList = res;
      this.cartListLength = this.cartList.length;
    });

    // Subscribe to ProductList change
    this._ecommerceService.onProductListChange.pipe(takeUntil(this._unsubscribeAll)).subscribe(res => {
      this.products = res;

      if (this.products.length) {
        // update product is in CartList : Boolean
        this.products.forEach(product => {
          product.isInCart = this.cartList.findIndex(p => p.productId === product.id) > -1;
        });
      }
    });
    */
  }
}
