import { Component, OnInit, ViewChild,} from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';

@Component({
  selector: 'app-navbar-barcode',
  templateUrl: './navbar-barcode.component.html'
})
export class NavbarBarcodeComponent implements OnInit {
  @ViewChild('scanner') scanner: ZXingScannerComponent;
  
  public hasCamerasFound = false;
  public camerasFound;
  public device;
  public modal;

  constructor(private modalService: NgbModal, private router: Router, private camera: Camera, private androidPermissions: AndroidPermissions) {}

  modalOpenVC(modalVC) {
    this.modal = this.modalService.open(modalVC, {
      centered: true
    });
  }

  onCodeResult(resultString: string): void {
    // first to catalog to be possible to work when already in shop
    this.router.navigate(['/apps/e-commerce/catalogs']).then(() => {
      this.router.navigate(['/apps/e-commerce/shop/'],{ queryParams: {q: resultString}}).then(() => {
        this.modal.close();
      })
    })
  }

  camerasNotFoundHandler(camerasFound):void {
    this.hasCamerasFound = false;
    this.camerasFound=null;
  }

  camerasFoundHandler(camerasFound):void {
    this.hasCamerasFound = true;
    this.camerasFound=camerasFound;
    this.device=this.camerasFound[0]
  }

  changeCamera() {
    if (this.device) {
      const currentDevice = this.device;
      const nextDevice = this.camerasFound.find(device => device.deviceId !== currentDevice.deviceId);
      if (nextDevice) {
        this.device = nextDevice;
      }
    }
  }

  requestCameraPermission() {
    this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.CAMERA).then(
      result => {
        if (result.hasPermission) {
          // Camera permission already granted
          // Your code to access the camera goes here
        } else {
          // Request camera permission
          this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.CAMERA).then(
            success => {
              if (success.hasPermission) {
                // Camera permission granted after request
                // Your code to access the camera goes here
              } else {
                // Camera permission denied even after request
                console.log('Camera permission denied.');
              }
            },
            err => {
              console.error(err);
            }
          );
        }
      },
      err => {
        console.error(err);
      }
    );
  }

  ngOnInit(): void {
    try{
      this.requestCameraPermission()
    } catch {}
  }
}
