<li ngbDropdown class="nav-item dropdown-cart mr-25" *ngIf="router.url !== '/apps/orders/cart'">
  <a class="nav-link" tabindex="0" ngbDropdownToggle id="navbarCartDropdown" (click)="updateCart()"><span
      [data-feather]="'shopping-cart'" [class]="'ficon'"></span></a>
  <ul ngbDropdownMenu aria-labelledby="navbarCartDropdown" class="dropdown-menu dropdown-menu-media dropdown-menu-right">


    <!-- Cart header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">Carrinho</h4>
        <div class="badge badge-pill badge-light-primary" *ngIf="cartLength > 0">{{ cartLength || "0" }} Itens</div>
      </div>
    </li>
    <!--/ Cart header -->
    <div *ngIf="loaded != 1 && cartLength == 0" style="padding: 50px;">
      <div class="loading">
        <div class="effect-1 effects"></div>
        <div class="effect-2 effects"></div>
        <div class="effect-3 effects"></div>
      </div>
    </div>

    <!-- Cart content -->
    <li class="scrollable-container media-list" [perfectScrollbar]>
      <ng-container *ngIf="cartLength > 0">
        <ng-container *ngFor="let product of products">
          <div class="media align-items-center">
            <img class="d-block rounded mr-1" [src]="product.image" alt="donuts" width="62"
              onerror="this.onerror=null;this.src='assets/images/noimage.png';" />
            <div class="media-body">
              <button class="btn p-0" (click)="removeFromCart(product)">
                <i class="ficon cart-item-remove" data-feather="x"></i>
              </button>
              <div class="media-heading">
                <h6 class="cart-item-title">
                  <a class="text-body" [routerLink]="'/apps/e-commerce/details/' + product.ref">{{
                    product.design
                    }}</a>
                </h6>
                <small class="cart-item-by">{{ product.ref }} - {{ product.model }}</small>
              </div>
              <div class="cart-item-qty">
                <div class="input-group touchspin-cart">
                  <core-touchspin [numberValue]="product.qt" [minValue]="1" (onChange)="editCart(product, $event)">
                  </core-touchspin>
                </div>
              </div>
              <h5 class="cart-item-price">{{ product.price*product.qt | number:'.2' }} €</h5>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </li>
    <!--/ Cart content -->

    <!-- Cart footer -->
    <li class="dropdown-menu-footer" *ngIf="cartLength > 0">
      <div class="d-flex justify-content-between mb-1">
        <h6 class="font-weight-bolder mb-0">Total:</h6>
        <h6 class="text-primary font-weight-bolder mb-0">{{cartTotal | number:'.2' }} €</h6>
      </div>
      <a class="btn btn-primary btn-block" routerLink="/apps/orders/cart">Comprar</a>
    </li>
    <li class="dropdown-menu-footer" *ngIf="cartLength == 0 && loaded == 1">
      <h4 style="text-align: center; padding: 5px;">O carrinho está vazio!</h4>
      <a class="btn btn-primary btn-block" routerLink="/apps/e-commerce/shop">Explorar produtos</a>
    </li>
    <!--/ Cart footer -->
  </ul>
</li>