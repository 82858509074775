import { CoreMenu } from '@core/types';

//? DOC: http://localhost:7777/demo/vuexy-angular-admin-dashboard-template/documentation/guide/development/navigation-menus.html#interface

export const menu: CoreMenu[] = [
  {
    id: 'home',
    title: 'Início',
    translate: '',
    type: 'item',
    icon: 'home',
    url: '/dashboard/home',
  },
  {
    id: 'product',
    title: 'Produtos',
    translate: '',
    type: 'section',
    children: [
      {
        id: 'products',
        title: 'Produtos',
        translate: '',
        type: 'item',
        icon: 'search',
        url: 'apps/e-commerce/shop'
      },
      {
        id: 'catalog',
        title: 'Catálogos',
        translate: '',
        type: 'item',
        icon: 'file-text',
        url: 'apps/e-commerce/catalogs'
        //url: 'https://euroed.pt/catalogo-geral/',
        //externalUrl: true,
        //openInNewTab: false,
      },
      {
        id: 'campanhas',
        title: 'Campanhas',
        translate: '',
        type: 'item',
        icon: 'percent',
        url: 'apps/e-commerce/campanhas',
        role: ['Cliente', 'Comercial', 'Colaborador', 'Gestor'],
      }
    ]
  },
  {
    id: 'encomenda',
    title: 'Encomendas',
    translate: '',
    type: 'section',
    role: ['Cliente', null],
    children: [
      {
        id: 'encomendas',
        title: 'Encomendas',
        translate: '',
        type: 'item',
        icon: 'package',
        url: '/apps/orders/history',
        role: ['Cliente', null],
      },
      {
        id: 'pendentes',
        title: 'Pendentes',
        translate: '',
        type: 'item',
        icon: 'clock',
        url: 'apps/orders/pending',
        role: ['Cliente'],
      },
      {
        id: 'cart',
        title: 'Carrinho',
        translate: '',
        type: 'item',
        icon: 'shopping-cart',
        url: 'apps/orders/cart',
        role: ['Cliente'],
      }
    ]
  },
  {
    id: 'repair',
    title: 'Reparações',
    translate: '',
    type: 'section',
    role: ['Cliente'],
    children: [
      {
        id: 'repairs',
        title: 'Reparações',
        translate: '',
        type: 'item',
        icon: 'tool',
        url: 'apps/repairs/history',
        role: ['Cliente'],
      },
      {
        id: 'repairnew',
        title: 'Pedir reparação',
        translate: '',
        type: 'item',
        icon: 'plus',
        url: 'apps/repairs/request',
        role: ['Cliente'],
      }
    ]
  },
  {
    id: 'other',
    title: 'Outros',
    translate: '',
    type: 'section',
    role: ['Cliente'],
    children: [
      {
        id: 'cg',
        title: 'Condições Gerais',
        translate: '',
        type: 'item',
        icon: 'file',
        url: 'https://cg.euroed.pt/',
        externalUrl: true,
        openInNewTab: true,
        role: ['Cliente']
      }
    ]
  },
  {
    id: 'account',
    title: 'Conta',
    translate: '',
    type: 'section',
    role: ['Cliente', 'Comercial', null, 'Colaborador', 'Gestor'],
    children: [
      {
        id: 'login',
        title: 'Iniciar sessão',
        translate: '',
        type: 'item',
        icon: 'lock',
        url: '/pages/authentication/login-v2',
        role: [null],
      },
      {
        id: 'logout',
        title: 'Terminar sessão',
        translate: '',
        type: 'item',
        icon: 'power',
        url: 'pages/logout',
        role: ['Cliente', 'Comercial', 'Colaborador', 'Gestor'],
      }
    ]
  }
  /*
  {
    id: 'account',
    title: 'Conta',
    translate: '',
    type: 'section',
    role: ['Cliente'],
    children: [
      {
        id: 'logout',
        title: 'Terminar sessão',
        translate: '',
        type: 'item',
        icon: 'power',
        url: 'pages/logout'
      }
    ]
  }*/
];
