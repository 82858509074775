import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Pipe, PipeTransform } from '@angular/core';
import { User } from 'app/auth/models';
import { BehaviorSubject } from 'rxjs';

@Pipe({
    name: 'authenticate_media'
})
export class AuthMediaPipe implements PipeTransform {
    private currentUserValue;

    constructor(private _httpClient: HttpClient) {
        this.currentUserValue = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser'))).value;
    }
    
    async transform(src: string): Promise<string> {

        const token = this.currentUserValue;
        const headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        });
        const imageBlob = await this._httpClient.get(src, { headers: headers, responseType: 'blob' }).toPromise();
        const reader = new FileReader();

        return new Promise((resolve, reject) => {

            reader.onloadend = () => resolve(reader.result as string);
            reader.readAsDataURL(imageBlob);
            
        });
    }
}