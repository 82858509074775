<li class="nav-item d-lg-block" >
    <a class="nav-link nav-link-style btn" (click)="modalOpenVC(modalVC)">
      <span [ngClass]="'icon-camera'" class="ficon font-medium-5 feather"></span>
    </a>
</li>

<!-- Modal -->
<ng-template #modalVC let-modal>
    <div class="modal-header">
      <h5 class="modal-title" id="exampleModalCenterTitle">Leitor Código de Barras</h5>
      <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <p *ngIf="!hasCamerasFound">
            <b>A carregar...</b><br><br>
            Caso o problema persista:<br>
            1. Permita acesso à câmera.<br>
            2. Verifique se existe algum problema com a sua câmera.
        </p>
        <zxing-scanner id="scanner"
        [torch]="true"
        [(device)]="device"
        (scanSuccess)="onCodeResult($event)"
        (camerasFound)="camerasFoundHandler($event)"
        (camerasNotFound)="camerasNotFoundHandler($event)"
        [formats]="['EAN_13']"
        ></zxing-scanner>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" style="margin-left: auto; margin-right: auto;" (click)="changeCamera()" rippleEffect>
            Trocar Câmera
          </button> 
    </div>
  </ng-template>
  <!-- / Modal -->

<!--<zxing-scanner></zxing-scanner>-->