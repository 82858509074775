import { NgModule } from '@angular/core';

import { FilterPipe } from '@core/pipes/filter.pipe';

import { InitialsPipe } from '@core/pipes/initials.pipe';

import { SafePipe } from '@core/pipes/safe.pipe';
import { StripHtmlPipe } from '@core/pipes/stripHtml.pipe';
import { AuthMediaPipe } from '@core/pipes/athenticate_media.pipe';
import { BypassSafeMediaPipe } from '@core/pipes/bypass_safe_media.pipe';

@NgModule({
  declarations: [InitialsPipe, FilterPipe, StripHtmlPipe, SafePipe, BypassSafeMediaPipe, AuthMediaPipe],
  imports: [],
  exports: [InitialsPipe, FilterPipe, StripHtmlPipe, SafePipe, BypassSafeMediaPipe, AuthMediaPipe]
})
export class CorePipesModule {}
