import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot } from '@angular/router';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthenticationService } from 'app/auth/service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  /**
   * @param {Router} _router
   * @param {AuthenticationService} _authenticationService
   */
  constructor(private _router: Router, private _authenticationService: AuthenticationService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(err => {
        console.log(this._router.url)
        if ([401, 403].indexOf(err.status) !== -1 && this._router.url.split('?')[0]  != '/pages/authentication/login-v2') {
          const currentUser = this._authenticationService.currentUserValue;
          if (currentUser) {
            this._router.navigate(['/pages/miscellaneous/not-authorized']);
          } else if (err.url.includes('/api/user/cart')) {
            //pass
          } else {
            this._router.navigate(['/pages/authentication/login-v2']);
          }
        }
        // throwError
        const error = err.error.message || err.statusText;
        return throwError(error);
      })
    );
  }
}
