<!-- Footer -->
<p class="clearfix mb-0">
  <span class="float-md-left d-block d-md-inline-block mt-25">
    COPYRIGHT &copy; {{ year }}
    <a class="ml-25" href="https://euroed.pt/" target="_blank">Euroed II - Distribuição, Lda </a>
    <span class="d-none d-sm-inline-block"> - Todos os direitos reservados</span>
  </span>
</p>
<!--/ Footer -->

<!-- Move to top Button-->
<app-scroll-top *ngIf="coreConfig.layout.scrollTop"></app-scroll-top>

<!-- Buynow Button-->
<div class="buy-now" *ngIf="isMobileOrTablet">
  <a (click)="installApp();" class="btn btn-danger" *ngIf="coreConfig.layout.buyNow && !appInstalled">
    <span [data-feather]="'download'" [class]="'mr-25'"></span>
    Instalar</a>
</div>

<ng-template #modaliOS let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalCenterTitle">Instalar Aplicação</h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" tabindex="0" ngbAutofocus>
    <video autoplay muted [muted]="'muted'" loop controls id="myVideo" width="100%">
      <source src="assets\video\ios_pwa_install.mp4" type="video/mp4">
    </video>
  </div>
</ng-template>